import { useCallback, useState } from 'react';
import IconAlert from 'components/IconAlert';

const ClearCookiesBanner = () => {
  const [previewState, setPreviewState] = useState(true);

  const clearPreviewCookies = useCallback(async () => {
    const url = process.env.NEXT_PUBLIC_LOCAL_URL + '/api/exit-preview';
    const response = await fetch(url);

    if (response.ok) {
      setPreviewState(false);
    }
  }, [previewState]);

  return (
    <div
      className={
        'group fixed bottom-0 z-50 flex w-full items-center justify-start bg-white py-16 text-sm text-gray-dark ' +
        (previewState ? '' : 'hidden')
      }
      style={{ height: '50px' }}
    >
      <IconAlert className={`ml-16 mr-8 !text-gray-dark`} /> Sanity Preview Active
      <button
        type="button"
        className="btn-xs btn-danger-outline border-red-400 btn text-h6-display absolute right-0 mr-16 border text-red"
        onClick={clearPreviewCookies}
      >
        Exit preview mode
      </button>
    </div>
  );
};

export default ClearCookiesBanner;
