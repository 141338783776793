import React, { ComponentProps } from 'react';
import { withPageStaticProps } from 'utils/header';
import { getDocumentToBuild, SanityPageProps, withSanityPageProps } from 'components/sanity/withSanityPageProps';
import PageBuilder from 'components/sanity/PageBuilder';
import { GetStaticPropsContext } from 'next';
import { getPageSettings, pageByIdQuery } from '@root/server/services/Pages';
import { components, PortableText } from 'components/sanity/serializers';

const getIndexProps = withSanityPageProps(async ({ preview = false }: GetStaticPropsContext) => {
  const pageSettings = await getPageSettings();

  let { published, draft } = pageSettings.homepage;

  if (!published) {
    throw new Error('Query for homepage did not return all the expected documents');
  }

  // If we don't currently have a draft, we'll use the published version
  // in case someone views the page in preview mode
  draft ??= published;

  const page = getDocumentToBuild([published, draft], preview);

  return {
    props: {
      data: {
        page,
        query: pageByIdQuery,
        queryParams: {
          id: preview ? draft._id : published._id,
        },
      },
    },
  };
});

export const getStaticProps = withPageStaticProps(getIndexProps);

const DefaultLayout2x1ImageLeft = components['layout2x1ImageLeft'];
const DefaultFAQSection = components['faqSection'];

const indexSerializers = {
  layout2x1ImageRight: (props: ComponentProps<typeof components['layout2x1ImageRight']>) => (
    <div
      className={`m-section container ${props.spaceTop ? 'pt-0' : ''} ${props.spaceBottom ? 'pb-0' : ''} ${
        props.backgroundColor
      }`}
    >
      <div className="row">
        <div className="col-span-12 lg:col-span-7">{props.children && <PortableText content={props.children} />}</div>

        <div className="mt-3 col-start-1 col-end-13 sm:col-start-6 sm:-mt-64 lg:col-start-8 lg:mt-0">
          <PortableText content={props[`${props.mediaType}`]} />
        </div>
      </div>
    </div>
  ),
  layout2x1ImageLeft: (props: ComponentProps<typeof DefaultLayout2x1ImageLeft>) => (
    <div className="sm:container sm:px-0">
      <DefaultLayout2x1ImageLeft {...props} />
    </div>
  ),
  faqSection: (props: ComponentProps<typeof DefaultFAQSection>) => (
    <div className="container">
      <DefaultFAQSection {...props} />
    </div>
  ),
  line: (_: ComponentProps<typeof components['line']>) => <hr className="!my-32 border-gray-light" />,
};

const Index = (props: SanityPageProps) => (
  <div className="mb-128">
    <PageBuilder serializers={indexSerializers} {...props} />
  </div>
);

export default Index;
