import Head from 'next/head';
import ClearCookiesBanner from './ClearCookiesBanner';
import { urlFor, usePreviewSubscription } from '@root/lib/sanity';
import { Page, SanityImageAsset, SanityReference } from '../../../sanity/schema-types';
import { SanityPageProps } from './withSanityPageProps';
import { PortableText, SerializerContext, Serializers } from './serializers';

type MaybeAnAsset = SanityImageAsset | SanityReference<SanityImageAsset> | undefined | null;

const getImageUrl = (asset: MaybeAnAsset): string | null => {
  if (asset) {
    return urlFor(asset).url();
  }
  return null;
};

export type PageBuilderProps = SanityPageProps & {
  serializers?: Serializers;
};

export default function PageBuilder({ data, preview, serializers }: PageBuilderProps) {
  const { data: pageData } = usePreviewSubscription<Page>(data?.query, {
    params: data?.queryParams ?? {},
    initialData: data?.page,
    enabled: preview,
  });

  const { page } = data;

  const ogTitle = page?.opengraphPage?.title ?? '';
  const ogDescription = page?.opengraphPage?.description ?? '';

  const serializerOptions = {
    serializers,
    data: data.static,
  };

  return (
    <>
      <Head>
        <title>{page.meta?.title ?? page?.pageName}</title>
        <meta name="description" content={page?.meta?.description ?? ''} />

        {page?.robotsTxtInclusion && <meta name="robots" content="noindex, nofollow" />}

        {page?.opengraphPage && (
          <>
            <meta property="og:url" content={`${process.env.NEXT_PUBLIC_LOCAL_URL}/${page.slug?.current}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={ogTitle.trim()} />
            <meta property="og:description" content={ogDescription.trim()} />
            <meta property="og:image" content={getImageUrl(page.opengraphPage?.image?.asset) ?? ''} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="menguin.com" />
            <meta property="twitter:url" content={`${process.env.NEXT_PUBLIC_LOCAL_URL}/${page.slug?.current}`} />
            <meta name="twitter:title" content={ogTitle.trim()} />
            <meta name="twitter:description" content={ogDescription.trim()} />
            <meta name="twitter:image" content={getImageUrl(page.opengraphPage?.image?.asset) ?? ''} />
          </>
        )}
      </Head>

      {preview && <ClearCookiesBanner />}

      <SerializerContext.Provider value={serializerOptions}>
        <PortableText content={pageData.pageBuilder ?? []} />
      </SerializerContext.Provider>
    </>
  );
}
